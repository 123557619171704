<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("journalEntry.journalEntryList") }}</span
        >
        <CommonAdd v-if="accessRight.includes('create')" :name="$t('journalEntry.journalEntry')" pathName="addJournalEntry" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else-if="journalEntryList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="journalEntryList.data"
              :items-per-page="limit"
              :page.sync="page"
              :server-items-length="journalEntryList.meta.total"
              style="word-break: break-word"
              :options.sync="pagination"
              item-class="py-4"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
              }"
              :search="search"
              @dblclick:row="redirectOnEditPage"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              must-sort
            >
              <template v-slot:[`item.date_create`]="{ item }">
                <span>{{ item.date_create && (profileById.date_format ? formatDateDDMMYYYY(new Date(item.date_create).toISOString().substring(0,10)) :new Date(item.date_create).toISOString().substring(0,10)) }}</span>
              </template>
              <template v-slot:[`item.status`]="props">
                <v-chip
                  :color="getColor(props.item.status)"
                  small
                  dark
                >
                  {{ statusList.filter(x => x.id == props.item.status)[0].name }}
                </v-chip>
              </template>
              <template v-slot:top>
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="2" class="text-end d-flex">
                      <v-autocomplete
                        :dense="true" 
                        hide-details="auto"
                        v-model="selectedStatus"
                        :items="statusList"
                        item-text="name"
                        item-value="id"
                        flat
                        @input="buildUrl"
                        return-object
                        single-line
                        :label="$t('quotation.field.status')"
                      />
                    </v-col>
                    <v-col cols="5"></v-col>
                    <v-col cols="2" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                    <v-col cols="3" class="text-end d-flex">
                      <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="journal_entry_date"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="40"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            class="me-2"
                            single-line
                            v-model="orderDateFormat"
                            :label="$t('order.from')"
                            @click:clear="journal_entry_date=''"
                            @input="buildUrl"
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="journal_entry_date"
                          @input="buildUrl"
                          @change="$refs.startDateMenu.save(journal_entry_date)"
                        >
                          <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="journal_entry_validate_date"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="160"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            single-line
                            v-model="orderValideDateFormat"
                            :label="$t('order.to')"
                            @click:clear="journal_entry_validate_date=''"
                            @input="buildUrl"
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="journal_entry_date ? journal_entry_date : ''"
                          v-model="journal_entry_validate_date"
                          @input="buildUrl"
                          @change="$refs.endDateMenu.save(journal_entry_validate_date)"
                        >
                          <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2"
                      >{{ $t("common.numberOfTotalRows") }}
                      {{ journalEntryList.meta.total }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="journalEntryList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else>
            {{ $t("common.noDataFound") }}</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import CommonAdd from '@/components/CommonAdd';

export default {
  name: "JournalEntry",
  components: {
    ListSkeleton,
    CommonAdd
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || "date_create"],
        sortDesc: this.$route.query.order_by ? this.$route.query.sortDec ? [true] : [false] : this.$route.query.sortDec ? [true] : [false],
      },
      selectedStatus: {
        id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'open' && 0 || null
      },
      startDateMenu: false,
      endDateMenu: false,
      journal_entry_date: this.$route.query.startDate || new Date(new Date().getFullYear(), 0, 2).toISOString().substring(0,10),
      journal_entry_validate_date: this.$route.query.endDate || new Date(new Date().getFullYear(), 11, 32).toISOString().substring(0,10),
      search: this.$route.query.search || "",
      listSkeleton: false,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
    };
  },
  computed: {
    orderDateFormat () {
      return this.profileById.date_format ? this.formatDateDDMMYYYY(this.journal_entry_date) : this.journal_entry_date
    },
    orderValideDateFormat () {
      return this.profileById.date_format ? this.formatDateDDMMYYYY(this.journal_entry_validate_date) : this.journal_entry_validate_date
    },
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        selectedStatus: this.selectedStatus?.id,
        journal_entry_date: this.journal_entry_date,
        journal_entry_validate_date: this.journal_entry_validate_date,
      };
    },
    statusList() {
      return [
        { name: this.$t('quotation.field.all'), id: null },
        { name: this.$t('quotation.field.open'), id: 0 },
        { name: this.$t('quotation.field.finished'), id: 1 },
      ]
    },
    ...mapGetters({
      journalEntryList: "journalEntry/journalEntryList",
      accessRight: "accessRight",
      profileById: "profile/profileById",
    }),
    headers() {
      return [
        { text: this.$t("journalEntry.no"), value: "journal_entry_number" },
        { text: this.$t("journalEntry.title"), value: "title" },
        { text: this.$t("journalEntry.referenceThree"), value: "date_create" },
        { text: this.$t("journalEntry.status"), value: "status" },
      ];
    },
  },
  watch: {
    orderDateFormat () {
      this.journal_entry_date = this.profileById.date_format ? this.formatDateDDMMYYYY(this.orderDateFormat) : this.orderDateFormat
    },
    orderValideDateFormat () {
      this.journal_entry_validate_date = this.profileById.date_format ? this.formatDateDDMMYYYY(this.orderValideDateFormat) : this.orderValideDateFormat
    },
    params: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.paginate(false);
      },
      deep: true,
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
        this.page = Number(this.$route.query.page) || 1;
        this.search = this.$route.query.search || "";
        this.sortByValue = this.$route.query.order_by || null;
        this.sortDescValue = this.$route.query.sortDec ? true : null;
        this.journal_entry_date = this.$route.query.startDate || new Date(new Date().getFullYear(), 0, 2).toISOString().substring(0,10)
        this.journal_entry_validate_date = this.$route.query.endDate || new Date(new Date().getFullYear(), 11, 32).toISOString().substring(0,10)
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.commit("journalEntry/SET_JOURNAL_ENTRY_QUERY",this.$route.query);
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.pagination.sortBy = ["date_create"];
      this.pagination.sortDesc = [true];
    }
    this.$store.dispatch("profile/GetCompanyById");
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
    },
    getColor (status) {
      if (status == 0) return 'orange'
      else if (status == 1) return 'green'
    },
    async paginate() {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
        selectedStatus,
        journal_entry_date,
        journal_entry_validate_date
      } = this.params;
      let concateWhereAnd = '';
      let whereAndVal = '|';
      concateWhereAnd += (selectedStatus || selectedStatus == 0) ? 'status,' : '';
      whereAndVal += (selectedStatus || selectedStatus == 0) ? selectedStatus+',' : '';
      concateWhereAnd += journal_entry_date ? 'from_date_create,' : '';
      concateWhereAnd += journal_entry_validate_date ? 'to_date_create,' : '';
      whereAndVal += journal_entry_date ? this.profileById.date_format ? this.parseDateYYYYMMDD(journal_entry_date)+',' : journal_entry_date+',' : '';
      whereAndVal += journal_entry_validate_date ? this.profileById.date_format ? this.parseDateYYYYMMDD(journal_entry_validate_date)+',' : journal_entry_validate_date+',' : '';
      await this.$store.dispatch("journalEntry/GetJournalEntry", {
        page: page,
        limit: itemsPerPage,
        order_by: sortBy?.[0] ? sortBy?.[0]+',journal_entry_number|'+(sortDesc?.[0] ? 'desc' : 'asc')+',desc' : 'date_create,journal_entry_number|'+(sortDesc?.[0] ? 'asc' : 'desc')+',desc',
        where_like: this.search ? sortBy[0] ? sortBy[0]+'|'+this.search : sortBy[1] ? sortBy[1]+'|'+this.search : sortBy[2] ? sortBy[2]+'|'+this.search : sortBy[3] ? sortBy[3]+'|'+this.search : 'journal_entry_number,title|'+ this.search : 'journal_entry_number,title|',where_and: concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1) ? concateWhereAnd.slice(0, -1)+whereAndVal.slice(0, -1) : undefined,
      })
      .then(() => (this.listSkeleton = false));
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.search = this.search;
      obj.page = this.page;
      obj.order_by = this.sortByValue;
      obj.startDate = this.journal_entry_date;
      obj.endDate = this.journal_entry_validate_date;
      obj.status = this.selectedStatus?.id;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "page" && val === 1) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router
        .replace({
          name: "JournalEntry",
          query: {
            ...obj,
          },
        })
        .catch(() => {});
      this.$store.commit("journalEntry/SET_JOURNAL_ENTRY_QUERY", obj);
    },
    redirectOnEditPage(event, { item }) {
      const path = 'journal-entry/journalEntryAction/' + item.id
      // this.accessRight.includes("edit") || this.accessRight.includes("show")
      //   ? window.open(path, '_blank') : ''
      this.accessRight.includes("edit") || this.accessRight.includes("show")
        ? this.$router.push(path) : ''
    },
  },
};
</script>
